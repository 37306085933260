define('ems/helpers/app-helper', ['exports', 'ember', 'ems/config/environment'], function (exports, _ember, _emsConfigEnvironment) {
  exports.appHelper = appHelper;

  function appHelper(pageOpts) {
    var settings = {
      env: {
        name: _emsConfigEnvironment['default'].name,
        mode: _emsConfigEnvironment['default'].mode,
        key: _emsConfigEnvironment['default'].name + ":" + _emsConfigEnvironment['default'].mode,
        url: _emsConfigEnvironment['default'].serverUrl,
        root: _emsConfigEnvironment['default'].rootURL,
        base: _emsConfigEnvironment['default'].baseURL
      },
      log: {
        debug: true
      },
      auth: {
        enableAuth: _emsConfigEnvironment['default'].enableLogin,
        enableRoles: _emsConfigEnvironment['default'].enableRoles
      },
      page: pageOpts
    };
    var page = new GlobalApp(settings);
    page.init();
    return page;
  }

  exports['default'] = _ember['default'].Helper.helper(appHelper);
});