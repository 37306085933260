define('ems/components/job-view', ['exports', 'ems/helpers/app-helper'], function (exports, _emsHelpersAppHelper) {
  exports['default'] = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.errors = [];
      console.log("app-job-search: init");
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      console.log(arguments);
      this.set('errors', []);
    },

    didRender: function didRender() {
      var app = (0, _emsHelpersAppHelper.appHelper)();
      app.load(new JobView({}));
    },

    actions: {
      required: function required(event) {}
    }
  });
});