define('ems/components/payroll-hours-report-by-date-range', ['exports', 'ems/helpers/app-helper'], function (exports, _emsHelpersAppHelper) {
    exports['default'] = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.errors = [];
            console.log("payroll-cus-yearly-hours-report: init");
        },

        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);
            console.log(arguments);
            this.set('errors', []);
        },

        didRender: function didRender() {
            var app = (0, _emsHelpersAppHelper.appHelper)();
            app.load(new PayrollHoursReportByDateRange({}));
        },

        actions: {
            required: function required(event) {}
        }
    });
});
/**
 * Created by skodavati on 7/20/17.
 */