define("ems/utils/protech/app.navitems", ["exports"], function (exports) {
  exports["default"] = navItems;

  function navItems() {

    var items = [{ id: "", url: "pro-new-cust.html", roles: "admin", icon: "ion-flask", name: "Customers", items: [{ id: "", url: "", roles: "@parent", icon: "", name: "Add New Customer" }, { id: "", url: "", roles: "@parent", icon: "", name: "Search Customers" }, { id: "", url: "", roles: "@parent", icon: "", name: "Active Customers List" }, { id: "", url: "", roles: "@parent", icon: "", name: "Inactive Customers List" }, { id: "", url: "", roles: "@parent", icon: "", name: "Companies Accepting Temps" }]
    }, { id: "", url: "pro-new-appl.html", roles: "admin", icon: "ion-settings", name: "Employees", items: [{ id: "", url: "", roles: "@parent", icon: "", name: "Add New Application" }, { id: "", url: "", roles: "@parent", icon: "", name: "List Available Candidates" }, { id: "", url: "", roles: "@parent", icon: "", name: "Active Employees List" }, { id: "", url: "", roles: "@parent", icon: "", name: "Inactive Employees List" }, { id: "", url: "", roles: "@parent", icon: "", name: "Search Employee by City" }, { id: "", url: "", roles: "@parent", icon: "", name: "Address Updates" }, { id: "", url: "", roles: "@parent", icon: "", name: "Employee Skills Report" }]
    }, { id: "", url: "pro-template.html", roles: "admin", icon: "ion-compose", name: "Jobs", items: [{ id: "", url: "", roles: "@parent", icon: "", name: "Add New Job" }, { id: "", url: "", roles: "@parent", icon: "", name: "Search Job" }, { id: "", url: "", roles: "@parent", icon: "", name: "Search Job By Customer" }, { id: "", url: "", roles: "@parent", icon: "", name: "Search Job By Date" }]
    }, { id: "", url: "pro-reports.html", roles: "admin", icon: "ion-stats-bars", name: "Reports", items: [{ id: "", url: "pro-reports.html?id=0", roles: "@parent", icon: "", name: "No of Employee By Customer Name" }, { id: "", url: "pro-reports.html?id=1", roles: "@parent", icon: "", name: "No of Employees by City" }, { id: "", url: "pro-reports.html?id=2", roles: "@parent", icon: "", name: "No of Employee by Age" }, { id: "", url: "pro-reports.html?id=3", roles: "@parent", icon: "", name: "No of Employee by Language" }, { id: "", url: "pro-reports.html?id=4", roles: "@parent", icon: "", name: "Weekly Birthday Report" }, { id: "", url: "pro-reports.html?id=5", roles: "@parent", icon: "", name: "Monthly Hires Report" }, { id: "", url: "pro-reports.html?id=6", roles: "@parent", icon: "", name: "Hire Report With A Date Range" }]
    },
    //		{ id: "", url: "pro-reports.html?id=7", roles: "@parent", icon: "", name: "Employee Work Status" }
    { id: "", url: "pro-template.html", roles: "admin", icon: "ion-document", name: "Payroll", items: [{ id: "", url: "", roles: "@parent", icon: "", name: "Add Single Employee Hours" }, { id: "", url: "", roles: "@parent", icon: "", name: "Total Hours Summary" }, { id: "", url: "", roles: "@parent", icon: "", name: "Weekly Hours Report" }, { id: "", url: "", roles: "@parent", icon: "", name: "Monthly Hours Report" }, { id: "", url: "", roles: "@parent", icon: "", name: "Yearly Hours Report" }, { id: "", url: "", roles: "@parent", icon: "", name: "New Hires Report" }, { id: "", url: "", roles: "@parent", icon: "", name: "Hours Report With A Date Range" }, { id: "", url: "", roles: "@parent", icon: "", name: "Add Work Hours" }]
    }, { id: "", url: "pro-manage-data.html", roles: "admin", icon: "ion-grid", name: "Admin Functions", items: [{ id: "", url: "pro-manage-data.html?id=0", roles: "@parent", icon: "", name: "Users" }, { id: "", url: "pro-manage-data.html?id=1", roles: "@parent", icon: "", name: "Emails" }, { id: "", url: "pro-manage-data.html?id=2", roles: "@parent", icon: "", name: "Countries" }, { id: "", url: "pro-manage-data.html?id=3", roles: "@parent", icon: "", name: "States" }, { id: "", url: "pro-manage-data.html?id=4", roles: "@parent", icon: "", name: "Cities" }, { id: "", url: "pro-manage-data.html?id=5", roles: "@parent", icon: "", name: "Skill Categories" }, { id: "", url: "pro-manage-data.html?id=6", roles: "@parent", icon: "", name: "Skills" }, { id: "", url: "pro-manage-data.html?id=7", roles: "@parent", icon: "", name: "Skill Position" }, { id: "", url: "pro-manage-data.html?id=8", roles: "@parent", icon: "", name: "Job Department" }, { id: "", url: "pro-manage-data.html?id=9", roles: "@parent", icon: "", name: "Job Category" }, { id: "", url: "pro-manage-data.html?id=10", roles: "@parent", icon: "", name: "Experience" }]
    }];

    return items;
  }
});