define('ems/components/app-employee', ['exports', 'ems/helpers/app-helper'], function (exports, _emsHelpersAppHelper) {
  exports['default'] = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.errors = [];
      console.log("app-job: init");
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('errors', []);
      console.log("app-job: did update attr");
    },

    didRender: function didRender() {
      var app = (0, _emsHelpersAppHelper.appHelper)();
      app.load(new Employee({}));
      console.log("app-job: did render");
    },

    actions: {
      required: function required(event) {
        console.log("required");
      }
    }
  });
});