define('ems/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      selectEnvironment: function selectEnvironment() {
        var index = this.controllerFor('index');
        index.selectEnvironment();
      }
    }

  });
});