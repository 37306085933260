define('ems/routes/reports', ['exports', 'ember', 'ems/utils/protech/app.navitems'], function (exports, _ember, _emsUtilsProtechAppNavitems) {
  exports['default'] = _ember['default'].Route.extend({

    renderTemplate: function renderTemplate() {
      console.log('rendering reports tempalte');
      this.render('reports');
    },

    model: function model() {
      var items = (0, _emsUtilsProtechAppNavitems['default'])();
      return items;
    }

  });
});