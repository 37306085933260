define('ems/components/report-employees-by-age', ['exports', 'ems/helpers/app-helper'], function (exports, _emsHelpersAppHelper) {
  exports['default'] = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.errors = [];
      this.logEvent("init");
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.set('errors', []);
      this.logEvent("didUpdateAttrs");
    },

    didRender: function didRender() {
      var app = (0, _emsHelpersAppHelper.appHelper)();
      app.load(new Reports({}));
      this.logEvent("didRender");
    },

    logEvent: function logEvent(name) {
      console.log("report-employees-by-age: " + name);
    },

    actions: {
      required: function required(event) {
        this.logEvent("required");
      }
    }
  });
});