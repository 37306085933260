define('ems/router', ['exports', 'ember', 'ems/config/environment'], function (exports, _ember, _emsConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _emsConfigEnvironment['default'].locationType,
    rootURL: _emsConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('dashboard-executive');
    this.route('dashboard');
    this.route('home');

    this.route('managedata');
    this.route('manage-cache');
    this.route('manage-users');
    this.route('manage-roles');
    this.route('manage-permissions');
    this.route('manage-emails');
    this.route('manage-profile');
    this.route('manage-countries');
    this.route('manage-states');
    this.route('manage-cities');
    this.route('manage-skills');
    this.route('manage-skillscategories');
    this.route('manage-skillpositions');
    this.route('manage-jobdepartments');
    this.route('manage-jobcategory');
    this.route('manage-experience');
    this.route('about-app');

    this.route('reports');
    this.route('login');
    this.route('profile-edit');

    this.route('job-add');
    this.route('job');
    this.route('job-view');
    this.route('job-search');
    this.route('job-search-by-customer');
    this.route('job-search-by-date');

    this.route('customer-add');
    this.route('customer');
    this.route('customer-view');
    this.route('customers-search');
    this.route('customers-active');
    this.route('customers-inactive');
    this.route('customers-accepting-temps');
    this.route('customers-with-active-employees');
    this.route('customers-with-inactive-employees');

    this.route('report-employees-by-customer');
    this.route('report-employees-by-city');
    this.route('report-employees-by-age');
    this.route('report-employees-by-language');
    this.route('report-birthdays');
    this.route('report-monthly-hires');
    this.route('report-hire-report-by-dates');
    // this.route('report-employee-work-status');

    this.route('employee-add');
    this.route('employee');
    this.route('employee-search-by-city');
    this.route('employee-search-custom');
    this.route('employee-search');
    this.route('employee-comments');
    this.route('available-candidates');
    this.route('active-employees');
    this.route('inactive-employees');
    this.route('address-updates');
    this.route('employee-skills');
    this.route('employee-work-status');

    this.route('payroll-main');
    this.route('payroll-import');
    this.route('payroll-add-employee-hours');
    this.route('payroll-edit-emp-hours');
    this.route('payroll-add-work-hours');
    this.route('payroll-manage-weeks');
    this.route('payroll-monthly-hours');
    this.route('payroll-weekly-hours');
    this.route('payroll-cus-yearly-hours-report');
    this.route('payroll-cus-weekly-hours-report');
    this.route('payroll-emp-new-hires-report');
    this.route('payroll-hours-report-by-date-range');

    this.route('info-version');
    this.route('info-about');
    this.route('info-contact');
    this.route('info-help');
    this.route('info-whatsnew');

    this.route('help-overview');
    this.route('help-job');
    this.route('help-customer');
    this.route('help-employee');
    this.route('help-reports');
    this.route('help-payroll');
    this.route('help-roles');
    this.route('guide-overview');
  });

  exports['default'] = Router;
});