define('ems/controllers/reports', ['exports', 'ember'], function (exports, _ember) {
  //import { AppCo } from '../utils/app.company';

  exports['default'] = _ember['default'].Controller.extend({

    init: function init() {
      this._super.apply(this, arguments);
      this.errors = [];
      console.log('init');
    },

    actions: {
      toggleBody: function toggleBody() {
        console.log(Env.env);
      }
    }
  });
});